<template>
  <div class="p-8 justify-center items-center gap-2.5 inline-flex">
    <!-- Previous button -->
    <div
      v-if="parseInt(currentPage) > 1"
      class="flex-col justify-start items-center gap-2.5 inline-flex"
    >
      <NuxtLink
        :to="`${linkPrefix}/${resource}?page=${parseInt(currentPage) - 1}&${filters}`"
        external
      >
        <div
          class="px-2.5 py-2 bg-gradient-to-b from-neutral-800 to-zinc-900 rounded border border-black justify-center items-center gap-1 flex"
        >
          <div class="font-normal text-white">
            <font-awesome-icon class="h-3" icon="fa-regular fa-chevron-left" />
          </div>
        </div>
      </NuxtLink>
    </div>

    <!-- First page -->
    <div v-if="pages[0] > 1">
      <NuxtLink :to="`${linkPrefix}/${resource}?page=1&${filters}`" external>
        <div
          class="px-2.5 py-2 bg-gradient-to-b from-neutral-800 to-zinc-900 rounded border border-black justify-center items-center gap-1 flex"
        >
          <div
            :class="[
              'text-lg font-normal',
              1 === parseInt(currentPage) ? 'text-sky-500' : 'text-white',
            ]"
          >
            1
          </div>
        </div>
      </NuxtLink>
    </div>

    <!-- Left ellipsis -->
    <div
      v-if="pages[0] > 2"
      class="self-stretch px-2.5 py-2 rounded justify-center items-center gap-1 flex"
    >
      <div class="text-neutral-500 text-lg font-normal">...</div>
    </div>

    <!-- Windowed pages -->
    <div v-for="item in pages" :key="item">
      <NuxtLink
        :to="`${linkPrefix}/${resource}?page=${item}&${filters}`"
        external
      >
        <div
          class="px-2.5 py-2 bg-gradient-to-b from-neutral-800 to-zinc-900 rounded border border-black justify-center items-center gap-1 flex"
        >
          <div
            :class="[
              'text-lg font-normal',
              item === parseInt(currentPage) ? 'text-sky-500' : 'text-white',
            ]"
          >
            {{ item.toLocaleString("en-US") }}
          </div>
        </div>
      </NuxtLink>
    </div>

    <!-- Right ellipsis -->
    <div
      v-if="pages[pages.length - 1] < totalPages - 1"
      class="self-stretch px-2.5 py-2 rounded justify-center items-center gap-1 flex"
    >
      <div class="text-neutral-500 text-lg font-normal">...</div>
    </div>

    <!-- Last page -->
    <div v-if="pages[pages.length - 1] < totalPages">
      <NuxtLink
        :to="`${linkPrefix}/${resource}?page=${totalPages}&${filters}`"
        external
      >
        <div
          class="px-2.5 py-2 bg-gradient-to-b from-neutral-800 to-zinc-900 rounded border border-black justify-center items-center gap-1 flex"
        >
          <div
            :class="[
              'text-lg font-normal',
              totalPages === parseInt(currentPage)
                ? 'text-sky-500'
                : 'text-white',
            ]"
          >
            {{ totalPages.toLocaleString("en-US") }}
          </div>
        </div>
      </NuxtLink>
    </div>

    <!-- Next button -->
    <div v-if="parseInt(currentPage) < totalPages">
      <NuxtLink
        :to="`${linkPrefix}/${resource}?page=${parseInt(currentPage) + 1}&${filters}`"
        external
      >
        <div
          class="px-2.5 py-2 bg-gradient-to-b from-neutral-800 to-zinc-900 rounded border border-black justify-center items-center gap-1 flex"
        >
          <div class="font-normal text-white">
            <font-awesome-icon class="h-3" icon="fa-regular fa-chevron-right" />
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    windowSize: {
      type: Number,
      default: 5,
    },
    filters: {
      type: String,
      default: "",
    },
  })

  const { inMembersArea } = useMembersArea()
  const linkPrefix = inMembersArea.value ? "/members" : ""

  const pages = computed(() => {
    const start = Math.max(
      1,
      props.currentPage - Math.floor(props.windowSize / 2),
    )
    const end = Math.min(props.totalPages, start + props.windowSize - 1)
    return Array.from({ length: end - start + 1 }, (_, i) => start + i)
  })
</script>
